<template>
  <v-container class="grey lighten-5 mb-6" fluid>
    <v-row>
      <v-col>
        <v-card class="mx-auto form-card-padding">
          <v-toolbar flat>
            <v-toolbar-title class="grey--text">
              {{ $t('sectorApplication.edit.title') }}
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon>
              <v-icon>mdi-plus-circle-outline</v-icon>
            </v-btn>
            <template v-slot:extension>
              <v-tabs v-model="tabs" fixed-tabs>
                <v-tabs-slider></v-tabs-slider>
                <template v-if="page">
                  <v-tab
                    v-for="(language, index) in page.web_languages"
                    :href="'#' + index"
                    class="primary--text"
                    :key="'tab_' + index"
                  >
                    {{ language.label }}
                  </v-tab>
                </template>
              </v-tabs>
            </template>
          </v-toolbar>

          <v-divider></v-divider>

          <template v-if="page && translations">
            <v-tabs-items v-model="tabs">
              <v-tab-item
                v-for="(language, index) in page.web_languages"
                :key="'tab_content_' + index"
                :value="index"
              >
                <translations
                  :language="index"
                  :currentTranslations="translations"
                  v-on:translation-updated="updateTranslation"
                ></translations>
              </v-tab-item>
            </v-tabs-items>
          </template>

          <v-select
            v-model="sectorApplicationData.sector"
            :items="sectors"
            :menu-props="{ maxHeight: '400' }"
            :label="$t('sectorApplication.edit.sector.label')"
            :hint="$t('sectorApplication.edit.sector.hint')"
            persistent-hint
            item-value="id"
            item-text="internal_name"
          ></v-select>

          <v-card v-if="preview">
            <v-img
              :src="preview"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="200px"
            >
              <v-card-title v-text="$t('sectorApplication.edit.currentImage')"></v-card-title>
            </v-img>
          </v-card>

          <v-file-input
            :label="$t('sectorApplication.edit.sectorImage')"
            filled
            prepend-icon="mdi-camera"
            v-model="sectorApplicationData.image"
            id="sectorImage"
            style="margin-top: 20px"
            @change="onFileChange"
          ></v-file-input>

          <v-text-field
            v-model="sectorApplicationData.internalName"
            :counter="15"
            :label="$t('sectorApplication.edit.internalName')"
            required
          ></v-text-field>

          <v-btn
            color="success"
            class="mr-4 align-self-lg-end"
            @click="updateSectorApplication()"
          >
          {{ $t('sectorApplication.edit.controlls.update') }}
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
    <error-handler :error="error"></error-handler>
  </v-container>
</template>
<script>
import Translations from "../../components/translation/sector/Translations.vue";

export default {
  components: { Translations },
  name: "EditSectorApplication",
  data() {
    return {
      error: null,
      sectorApplication: null,
      sectorApplicationData: {
        sector: null,
        translations: {},
        image: null,
        internalName: null,
      },
      translations: null,
      sectors: [],
      preview: null,
      tabs: null,
    };
  },
  computed: {
    applicationData() {
      return this.$store.state.applicationData
    },
    page() {
      if(this.applicationData) {
        return this.applicationData; 
      }
      return []
    }
  },
  mounted() {
    this.$store.dispatch("loadApplicationData")
    this.fetchSectors();
    this.fetchSectorApplication();
  },
  methods: {
    fetchSectors() {
      this.$axios({
        method: "get",
        url: this.$globals.api.sector.getAll,
      })
        .then((response) => {
          this.sectors = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.error = error.response;
        });
    },
    fetchSectorApplication() {
      this.$axios({
        method: "get",
        url: this.$globals.api.sectorApplication.get + this.$route.params.id,
      })
        .then((response) => {
          this.translations = response.data.data.translations;
          this.preview = response.data.data.image_path;
          this.sectorApplicationData.internalName =
            response.data.data.internal_name;
          this.sectorApplicationData.sector = response.data.data.sector.id;
        })
        .catch((error) => {
          this.error = error.response;
        });
    },
    updateSectorApplication() {
      var formData = new FormData();

      var imagefile = document.querySelector("#sectorImage");

      if (imagefile.files[0]) formData.append("image", imagefile.files[0]);

      formData.append("sector", this.sectorApplicationData.sector);
      formData.append("internalName", this.sectorApplicationData.internalName);
      for (const language in this.sectorApplicationData.translations) {
        for (const languageValues in this.sectorApplicationData.translations[
          language
        ]) {
          formData.append(
            "translations[" + language + "][" + languageValues + "]",
            this.sectorApplicationData.translations[language][languageValues]
          );
        }
      }

      this.$axios({
        method: "post",
        url: this.$globals.api.sectorApplication.update + this.$route.params.id,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(() => {
          this.$router.push({ name: "sector-application/list" });
        })
        .catch((error) => {
          this.error = error.response;
        });
    },

    onFileChange() {
      if (this.sectorApplicationData.image != null) {
        this.preview = URL.createObjectURL(this.sectorApplicationData.image);
      } else {
        this.preview = null;
      }
    },

    updateTranslation(translationData) {
      this.sectorApplicationData.translations[translationData.language] =
        translationData.data;
    },
  },
};
</script>